import {
  BoxBoundary,
  Area,
  ComputedData,
  PackedRect,
  Solution,
  SolutionRect,
} from "./Models";
import { algorithms } from "./AlgoDict";

export function solveAndFindBest(
  docWidth: number,
  docHeight: number,
  spacing: number,
  docBorder: number,
  origRects: Array<PackedRect>,
  price: number,
  selectedAlgos: Array<string>
): Solution | undefined {

  if (selectedAlgos.length > 0 && origRects.length > 0) {
    let minArea = Number.MAX_VALUE;
    let best: Solution | undefined = undefined;

    for (const algo of selectedAlgos) {
      console.log("Running algorithm: " + algo);
      let result = undefined
      try {
        result = solve(
          docWidth,
          docHeight,
          spacing,
          docBorder,
          origRects,
          price,
          algo
        );
      } catch(error) {
        console.log("jaj", error)
      }

      console.log(" - Result: ", result);
      if(result === undefined) {
        console.log("Skipping ", algo)
        continue
      }
      const used = result.computedData.totalArea;
      if (used < minArea && checkClipping(result.computedData.rects)) {
        minArea = used;
        best = result;
        best.bestAlgo = algo;
      }
    }

    return best;
  } else {
    return solve(docWidth, docHeight, spacing, docBorder, [], price, "Example");
  }
}

export function solve(
  docWidth: number,
  docHeight: number,
  spacing: number,
  docBorder: number,
  origRects: Array<PackedRect>,
  price: number,
  selectedAlgo: string
): Solution | undefined {
  let area = {
    w: docWidth - spacing,
    h: docHeight - spacing,
  };

  let rects: Array<SolutionRect> = [];

  for(var i = 0; i < origRects.length; i ++) {
    for(var j = 0; j < origRects.length; j ++) {
      if(i === j) continue
      if(origRects[i].id === origRects[j].id) origRects[j].id = origRects[j].id + "-" + j.toString()
    }
  }

  origRects.forEach((r: PackedRect) => {
    for (var j = 0; j < r.cnt; j++) {
      const x = "-" + (j + 1);
      rects.push({
        id: r.id + x,
        pairId: r.id,
        w: r.w + spacing,
        h: r.h + spacing,
        x: 0,
        y: 0,
      });
    }
  });

  rects.sort(
    (a, b) => (a.w > b.w && -1) || (a.w < b.w && 1) || (a.h > b.h && -1) || 1
  );

  let bbox: BoxBoundary = {w: 0, h: 0, x: 0, y: 0};
  let cdata: Array<SolutionRect> = [];
  if (rects.length > 0) {

    /*const foundClass = algorithms.find(a => a.name === selectedAlgo)
    if(foundClass === undefined) {
      throw Error(`Selcted algo ${selectedAlgo} is not in algoDict`)
    }
    const solver = new (foundClass.func)
    const response = solver.solve(area, rects)*/
    const foundAlgo = algorithms.find(a => a.name === selectedAlgo)
    if(foundAlgo === undefined) {
      throw Error(`Selcted algo ${selectedAlgo} is not in algoDict`)
    }
    const response = foundAlgo.func(area, rects)
    // await axios.post(api + '/solve', data);
    console.log("alg response", response)
    if(response === null) return undefined
    cdata = response;
    //cdata = this.doSolve();
    bbox = findBoundingBox(cdata);
  }

  bbox.w = bbox.w + docBorder * 2;
  bbox.h = bbox.h + docBorder * 2;

  let allbox = {
    w: area.w,
    h: bbox.h,
    x: 0,
    y: bbox.y 
  };

  // Let's always go from top to bottom instead:
  const compData = getComputedData({
    allBox: allbox,
    boundingBox: bbox,
    spacing: spacing,
    price: price,
    unpricedSolutionRects: cdata,
    docBorder: docBorder,
  });

  if(
    compData.boundingBox.h > docHeight || compData.boundingBox.w > docWidth
  ) return undefined

  return {
    allArea: { w: area.w + spacing, h: area.h + spacing },
    computedData: compData,
    boundingBox: bbox,
    allBox: allbox,
    bestAlgo: ""
  };
}

export function checkClipping(rectangles: Array<SolutionRect>): boolean {
  for (let i = 0; i < rectangles.length; i++) {
    for (let j = i + 1; j < rectangles.length; j++) {
      if (rectanglesOverlap(rectangles[i], rectangles[j])) {
        return false;
      }
    }
  }
  return true;
}

export function rectanglesOverlap(
  rect1: SolutionRect,
  rect2: SolutionRect
): boolean {
  return !(
    rect1.x + rect1.w <= rect2.x || // rect1 is to the left of rect2
    rect2.x + rect2.w <= rect1.x || // rect2 is to the left of rect1
    rect1.y + rect1.h <= rect2.y || // rect1 is above rect2
    rect2.y + rect2.h <= rect1.y
  ); // rect2 is above rect1
}

export function manualSolve(
  docWidth: number,
  docHeight: number,
  spacing: number,
  docBorder: number,
  data: Array<SolutionRect>,
  price: number
): {
  allArea: Area;
  computedData: ComputedData;
  boundingBox: BoxBoundary;
  allBox: BoxBoundary;
} {

  let area = {
    w: docWidth - (spacing),
    h: docHeight - (spacing)
  }

  const bbox = findBoundingBox(data)
  bbox.w += (docBorder * 2);
  bbox.h += (docBorder * 2);

  const allbox: BoxBoundary = {
    w: area.w,
    h: bbox.h,
    x: 0,
    y: bbox.y  
  }

  const compData = getComputedData({
    allBox: allbox,
    boundingBox: bbox,
    spacing: spacing,
    price: price,
    unpricedSolutionRects: data,
    docBorder: docBorder
  })

  return {
    allArea: { "w" : area.w + spacing, "h": area.h + spacing},
    computedData: compData,
    boundingBox: bbox,
    allBox: allbox,
  }
}

export function getComputedData(props: {
  allBox: BoxBoundary,
  boundingBox: BoxBoundary,
  spacing: number,
  price: number,
  unpricedSolutionRects: Array<SolutionRect>,
  docBorder: number,
}): ComputedData {
  const data = props.unpricedSolutionRects;
  const aw = props.allBox.w + props.spacing;
  const ah = props.allBox.h + props.spacing;
  const totalArea = aw * ah;

  let usedArea = 0;
  for (let i = 0; i < data.length; i++) {
    let w = data[i].w - props.spacing
    let h = data[i].h - props.spacing
    usedArea += (w * h);
  }

  const wasted = (totalArea - usedArea) * props.price;
  let priceStats : { [key: string]: { one: number, all: number, cnt: number }} = {};
  let rects : Array<SolutionRect> = [];
  data.forEach((data_ : SolutionRect) => {  
      let x = data_.x + props.spacing + props.docBorder;
      let y = data_.y + props.spacing + props.docBorder;
      let w = data_.w - props.spacing;
      let h = data_.h - props.spacing;		
      
      if (!priceStats[data_.pairId])
      {
        let size = w * h;
        let p = (size / usedArea) * (totalArea * props.price);
        priceStats[data_.pairId] = { one: p, all: 0, cnt: 0 };
      }
      priceStats[data_.pairId].all += priceStats[data_.pairId].one;
      priceStats[data_.pairId].cnt += 1;

      rects.push({ 
        x: x,
        y: y,
        w: w,
        h: h,
        id: data_.id,
        pairId: data_.pairId,
        price: Math.round(priceStats[data_.pairId].one)
      });
  })
  return {
      allBox: props.allBox,
      boundingBox: props.boundingBox,
      spacing: props.spacing,
      price: props.price,
      allboxW: aw,
      allboxH: ah,
      rects: data,
      solvedRects: rects,
      totalArea: totalArea,
      usedArea: usedArea,
      wasted: wasted,
      priceStats: priceStats
  };
}

export function findBoundingBox(
  rectangles: Array<SolutionRect>
): BoxBoundary {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  if (rectangles.length) {
    rectangles?.forEach((rect) => {
      minX = Math.min(minX, rect.x);
      minY = Math.min(minY, rect.y);
      maxX = Math.max(maxX, rect.x + rect.w);
      maxY = Math.max(maxY, rect.y + rect.h);
    });
    return { x: minX, y: minY, w: maxX - minX, h: maxY - minY };
  }
  return {x: 0, y: 0, w: 0, h: 0};
}
