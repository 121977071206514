import {
  Box,
  Typography,
} from "@mui/material";
import '../../App.css';
import COLORS from "../FortesColors";


export default function PriceBox(props: {
  totalArea: number;
  usedArea: number;
  wasted: number;
  price: number;
  priceStats: { [key: string]: { one: number, all: number, cnt: number }};
}): JSX.Element {

  let ids = Object.keys(props.priceStats || {});
  ids.sort();
  const typographySx = {
    paddingRight: "20px",
    color: COLORS.darkPurple,
    fontSize: "100%",
    fontWeight: 600,
    maxWidth: "200px",
    maxHeight: "50px"
  }
  return (
      <div
        style={{
          margin: "20px",
          width: 400,
          background: "white",
        }}
      >
        
          <Typography {...typographySx}>
            Area usage
          </Typography>

          <Cell key_="Total area of the cut-out:" value={`${props.totalArea} units`}/>
          <Cell key_="Price for the cut-out:" value={`${(props.totalArea * props.price).toFixed(2)},-`}/>
          <Cell key_="Area used in the cut-out:" value={`${props.usedArea} units (${Math.round((props.usedArea / props.totalArea) * 100)} %)`}/>
          <Cell key_="Money wasted on empty space:" value={`${props.wasted?.toFixed(2)},-`}/>
          <br/>
          {ids.length > 0 &&
            <Typography {...typographySx}>
              Per unit
            </Typography>
          }

          {ids.map((id) => {
            return (<>
              <Cell 
                key_={`Price for 1× ${id}:`}
                value={`${props.priceStats[id].one.toFixed(2)},-`}
                isExtra={props.priceStats[id].cnt > 1}
              />
              
              {(props.priceStats[id].cnt > 1) && 
                <Cell 
                  key_={`Price for ${props.priceStats[id].cnt}× ${id}:`} 
                  value={`${props.priceStats[id].all.toFixed(2)},-`}
                />
              }
          
            </>)
          })}
      </div>
  );
}

function Cell(props: {
  key_: string,
  value: string,
  isExtra?: boolean
}) : JSX.Element {

  const sx = {
    paddingRight: "20px",
    color: props.isExtra ? "gray" : COLORS.darkPurple,
    fontSize: "90%",
    fontWeight: 500,
    maxWidth: "200px",
    maxHeight: "50px"
  }

  return (
  <Box 
    display="flex" justifyContent="space-between"
    sx={{paddingBlock:"10px", borderBlockEnd:`1px solid ${COLORS.separator}`}}
  >
    <Typography {...sx} >
      {props.key_}
    </Typography>

    <Typography {...sx} >
          {props.value}
    </Typography>
  </Box>
  )
}