import { Box, Typography } from "@mui/material";
import Logo from "../logo.svg";
import COLORS from "./FortesColors";
import { FortesButtonHighlighted } from "./FortesInputs/FortesButtons";
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';

export default function ForbinHeader(props: {
    height: number,
    exporFn: () => void
}) : JSX.Element {

  const typographySx = {
    color: COLORS.darkPurple,
    fontSize: "120%",
    fontWeight:500,
    height:80,
    alignContent: "center"
  }

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%" height={props.height}>
      <Box>
        <img src={Logo} height={78} style={{marginInlineEnd:"45px"}} alt="FORTES logo"/>

      </Box>

      <Box display="flex" flexDirection="row" justifyContent="space-between" width="530px" alignItems="center">
        <Typography {...typographySx}> Need a help? </Typography>
        <Box display="flex" flexDirection="row" alignItems="center" sx={{marginInlineEnd:"20px"}}>
          <PhoneInTalkOutlinedIcon sx={{marginInlineEnd:"10px"}}/>
          <Typography {...typographySx}> +420 605 013 046 </Typography>
        </Box>
        <FortesButtonHighlighted 
          highlightColor={COLORS.darkPurple} 
          contentColor="white" onClick={props.exporFn}
          height="50px" width="90px"
        >
          Export
        </FortesButtonHighlighted>
      </Box>
    </Box>
  )
}