class Guillotine {
  /*
		Made by Mirek Novotný.
		Converted to JS by Lukáš Horký with the help of... With the sabotage attempts of ChatGPT.
	*/
  cache = {}
  splitAndSolve(area, rects, layer) {
    if (this.abort) return []

    let innerNew = []
    let rectCount = rects.length

    if (rectCount > 4) {
      // for some configurations it could be better to split same rects into pairs (not done)
      /*if (rectCount <= 8) {				
				rects = Array.from({ length: Math.ceil(rectCount / 2) }, (_, index) => rects.slice(index * 2, index * 2 + 2));
			}
			else*/
      if (rectCount <= 16) {
        rects = Array.from({ length: Math.ceil(rectCount / 4) }, (_, index) =>
          rects.slice(index * 4, index * 4 + 4)
        )
      } else {
        // let chunkSize = Math.ceil(rectCount / 4);
        // rects = Array.from({ length: 4 }, (_, i) => rects.slice(i * chunkSize, (i + 1) * chunkSize));
        let rest = rectCount - 12
        rects = [
          rects.slice(0, rest),
          rects.slice(rest, rest + 4),
          rects.slice(rest + 4, rest + 8),
          rects.slice(rest + 8, rest + 12),
        ]
      }
      let newRects = []
      let newRectParts
      let rectPart
      let id = 0
      for (let rectPartIndex in rects) {
        rectPart = rects[rectPartIndex]
        let output = this.splitAndSolve(area, rectPart, layer + 1)
        if (output == undefined || output.length == 0) {
          this.abort = true
          return []
        }
        newRectParts = output[0]

        const min_x = Math.min(...newRectParts.map((rect) => rect[0]))
        const min_y = Math.min(...newRectParts.map((rect) => rect[1]))
        const max_x = Math.max(...newRectParts.map((rect) => rect[0] + rect[2]))
        const max_y = Math.max(...newRectParts.map((rect) => rect[1] + rect[3]))

        const bounding_box = [min_x, min_y, max_x, max_y]

        innerNew.push([].concat(newRectParts.concat([[max_x, max_y]])))

        newRects.push({ w: bounding_box[2], h: bounding_box[3] })
        id += 1
      }

      rects = this.deepCopy(newRects)
    }

    let parts = this.subSolve(area, rects, innerNew, layer)
    if (parts.length == 0) {
      this.abort = true
      return []
    }

    this.mainStructure[layer].push(this.deepCopy(parts))
    return parts
  }

  deepCopy(arr) {
    return Array.from(arr, (item) =>
      Array.isArray(item) ? this.deepCopy(item) : item
    )
  }

  solve(area, rects, type) {
    this.abort = false
    this.solutionType = type

    this.mainStructure = [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ]
    let newSolution
    let newSolutionAggregate = []

    let solution = this.splitAndSolve(area, rects, 0)
    if (this.abort) {
      return rects
    }
    let bottom = this.mainStructure.length
    for (var i = 0; i < 6; i++) {
      if (this.mainStructure[i].length == 0) {
        bottom = i
        break
      }
    }

    for (var layerIndex = 0; layerIndex < bottom; layerIndex++) {
      let layerDepth = this.mainStructure[layerIndex].length
      let parentIncrement = 0
      for (var rectDataIndex = 0; rectDataIndex < layerDepth; rectDataIndex++) {
        // console.log(util.inspect(this.mainStructure[layerIndex][rectDataIndex], { depth: 4 }));
        let childCount = this.mainStructure[layerIndex][rectDataIndex][1].length
        let parentCount =
          this.mainStructure[layerIndex][rectDataIndex][0].length

        if (childCount == 0) {
          newSolutionAggregate = newSolutionAggregate.concat(
            this.mainStructure[layerIndex][rectDataIndex][0]
          )
        } else {
          for (var parentIndex = 0; parentIndex < parentCount; parentIndex++) {
            // order children
            for (
              var childIndex = parentIndex;
              childIndex < parentCount;
              childIndex++
            ) {
              let boundBoxIndex =
                this.mainStructure[layerIndex][rectDataIndex][1][childIndex]
                  .length - 1
              let parentBoundBox =
                this.mainStructure[layerIndex][rectDataIndex][0][parentIndex]
              let childBoundBox =
                this.mainStructure[layerIndex][rectDataIndex][1][childIndex][
                  boundBoxIndex
                ]
              if (
                (parentBoundBox[2] == childBoundBox[1] &&
                  parentBoundBox[3] == childBoundBox[0]) ||
                (parentBoundBox[2] == childBoundBox[0] &&
                  parentBoundBox[3] == childBoundBox[1])
              ) {
                if (childIndex != parentIndex) {
                  let tmp =
                    this.mainStructure[layerIndex][rectDataIndex][1][childIndex]
                  this.mainStructure[layerIndex][rectDataIndex][1][childIndex] =
                    this.mainStructure[layerIndex][rectDataIndex][1][
                      parentIndex
                    ]
                  this.mainStructure[layerIndex][rectDataIndex][1][
                    parentIndex
                  ] = tmp

                  tmp =
                    this.mainStructure[layerIndex + 1][
                      childIndex + parentIncrement
                    ]
                  this.mainStructure[layerIndex + 1][
                    childIndex + parentIncrement
                  ] =
                    this.mainStructure[layerIndex + 1][
                      parentIndex + parentIncrement
                    ]
                  this.mainStructure[layerIndex + 1][
                    parentIndex + parentIncrement
                  ] = tmp
                }
                break
              }
            }
          }

          for (var parentIndex = 0; parentIndex < parentCount; parentIndex++) {
            // console.log(this.mainStructure[layerIndex][rectDataIndex][0][boxIndex][0], this.mainStructure[layerIndex][rectDataIndex][0][boxIndex][1]);
            let subBoxCount =
              this.mainStructure[layerIndex + 1][
                parentIndex + parentIncrement
              ][0].length
            let boundBoxIndex =
              this.mainStructure[layerIndex][rectDataIndex][1][parentIndex]
                .length - 1
            let rotated = false

            if (
              this.mainStructure[layerIndex][rectDataIndex][0][
                parentIndex
              ][2] ==
                this.mainStructure[layerIndex][rectDataIndex][1][parentIndex][
                  boundBoxIndex
                ][1] &&
              this.mainStructure[layerIndex][rectDataIndex][0][
                parentIndex
              ][3] ==
                this.mainStructure[layerIndex][rectDataIndex][1][parentIndex][
                  boundBoxIndex
                ][0]
            ) {
              rotated = true
            }

            for (
              var subBoxIndex = 0;
              subBoxIndex < subBoxCount;
              subBoxIndex++
            ) {
              if (rotated) {
                let tmp =
                  this.mainStructure[layerIndex + 1][
                    parentIndex + parentIncrement
                  ][0][subBoxIndex][0]
                this.mainStructure[layerIndex + 1][
                  parentIndex + parentIncrement
                ][0][subBoxIndex][0] =
                  this.mainStructure[layerIndex + 1][
                    parentIndex + parentIncrement
                  ][0][subBoxIndex][1]
                this.mainStructure[layerIndex + 1][
                  parentIndex + parentIncrement
                ][0][subBoxIndex][1] = tmp

                tmp =
                  this.mainStructure[layerIndex + 1][
                    parentIndex + parentIncrement
                  ][0][subBoxIndex][2]
                this.mainStructure[layerIndex + 1][
                  parentIndex + parentIncrement
                ][0][subBoxIndex][2] =
                  this.mainStructure[layerIndex + 1][
                    parentIndex + parentIncrement
                  ][0][subBoxIndex][3]
                this.mainStructure[layerIndex + 1][
                  parentIndex + parentIncrement
                ][0][subBoxIndex][3] = tmp

                this.mainStructure[layerIndex + 1][
                  parentIndex + parentIncrement
                ][0][subBoxIndex][0] +=
                  this.mainStructure[layerIndex][rectDataIndex][0][
                    parentIndex
                  ][0]
                this.mainStructure[layerIndex + 1][
                  parentIndex + parentIncrement
                ][0][subBoxIndex][1] +=
                  this.mainStructure[layerIndex][rectDataIndex][0][
                    parentIndex
                  ][1]
              } else {
                this.mainStructure[layerIndex + 1][
                  parentIndex + parentIncrement
                ][0][subBoxIndex][0] +=
                  this.mainStructure[layerIndex][rectDataIndex][0][
                    parentIndex
                  ][0]
                this.mainStructure[layerIndex + 1][
                  parentIndex + parentIncrement
                ][0][subBoxIndex][1] +=
                  this.mainStructure[layerIndex][rectDataIndex][0][
                    parentIndex
                  ][1]
              }
              // this.mainStructure[layerIndex + 1][boxIndex][0][subBoxIndex][0] = 8;
            }
            // console.log(this.mainStructure[layerIndex + 1][boxIndex][0]); //, this.mainStructure[layerIndex + 1][rectDataIndex][boxIndex]);
          }
          parentIncrement += parentCount
        }

        // console.log(util.inspect(this.mainStructure[layerIndex][rectDataIndex], { depth: 4 }));
      }
    }

    return newSolutionAggregate
  }

  subSolve(area, rects, inner, layer) {
    let key = JSON.stringify([area, rects])
    // if (this.cache[key]) return this.cache[key];

    if (rects.length > 6)
      throw "6 rectangles is maximum for this algorithm, unless you want to wait hundreds of years."

    //let rectsStart = [[100,30],[20,60],[80,30],[40,60]];
    let rectsStart = []
    for (let rect of rects)
      rectsStart.push([rect.w, rect.h, rect.id, rect.parId])
    let states = [[[[0, 0, area.w, area.h]], rectsStart, []]]
    let endStates = []
    let t = Date.now()
    let size = 1
    let cap = 1

    while (size > 0) {
      let [free, stateRects, pos] = states[0]
      states.shift()
      size--

      for (let f of free) {
        for (let recOrg of stateRects) {
          let r = recOrg

          for (var i = 0; i < 2; i++) {
            if (r[0] <= f[2] && r[1] <= f[3]) {
              let f1 = [f[0] + r[0], f[1], f[2] - r[0], r[1]]
              let f2 = [f[0], f[1] + r[1], f[2], f[3] - r[1]]

              let f3 = [f[0] + r[0], f[1], f[2] - r[0], f[3]]
              let f4 = [f[0], f[1] + r[1], r[0], f[3] - r[1]]

              let newPos = pos.slice()
              newPos.push([f[0], f[1], r[0], r[1], r[2], r[3]])
              if (stateRects.length === 1) {
                endStates.push(newPos)
              } else {
                let newRects = stateRects.slice()
                newRects.splice(stateRects.indexOf(recOrg), 1)
                let newFree1 = free.slice()
                newFree1.splice(free.indexOf(f), 1)
                let newFree2 = newFree1.slice()
                newFree1.push(f1)
                newFree1.push(f2)
                newFree2.push(f3)
                newFree2.push(f4)
                states.push([newFree1, newRects, newPos])
                states.push([newFree2, newRects, newPos])
                size += 2
              }
            }
            if (r[0] == r[1]) break
            r = [recOrg[1], recOrg[0], recOrg[2], recOrg[3]]
          }
        }
      }
    }

    let xMin = [10000, 0]
    let yMin = [10000, 0]
    let xyMin = [Math.pow(10000, 2), 0]
    let cMin = [Math.pow(20000, 2), 0]
    let dMin = [10000, 0]
    let minSum = [1000000, 0]

    for (let index = 0; index < endStates.length; index++) {
      let state = endStates[index]
      let xMax = 0
      let yMax = 0
      let sTotal = 0

      for (let i = 0; i < state.length; i++) {
        let r = state[i]
        let w = r[0] + r[2]
        let h = r[1] + r[3]
        sTotal += w + h
        if (w > xMax) {
          xMax = w
        }
        if (h > yMax) {
          yMax = h
        }
      }

      if (xMax < xMin[0]) {
        xMin = [xMax, index]
      }
      if (yMax < yMin[0]) {
        yMin = [yMax, index]
      }
      if (yMax * xMax < xyMin[0]) {
        xyMin = [yMax * xMax, index]
      }
      let c = Math.pow(yMax, 2) + Math.pow(xMax, 2)
      if (c < cMin[0]) {
        cMin = [c, index]
      }
      if (sTotal < minSum[0]) {
        minSum = [sTotal, index]
      }
      if (Math.abs(xMax - yMax) < dMin[0]) {
        dMin = [Math.abs(xMax - yMax), index]
      }
    }

    let val = [
      endStates[xMin[1]],
      endStates[yMin[1]],
      endStates[cMin[1]],
      endStates[xyMin[1]],
      endStates[minSum[1]],
    ]

    let selectedSolution
    if (this.shuffle > 0 && Math.random() < this.shuffle)
      selectedSolution = val[Math.floor(Math.random() * 5)]
    else if (
      this.packBottomLine &&
      (this.inner == undefined || this.inner.length == 0)
    )
      selectedSolution = val[2]
    else selectedSolution = val[this.solutionType]

    if (selectedSolution != undefined) val = [selectedSolution, inner]
    else return []

    this.cache[key] = this.deepCopy(val)
    return val
  }
}

function shuffleArray(arr) {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[arr[i], arr[j]] = [arr[j], arr[i]]
  }
  return arr
}

export default function solve(area, rects, type, depthMultiplier = 1) {
	const MAX_ITER = 200
	const MIN_ITER = 4
	const APPROX_TIME= 4000
  let count = rects.length
  let depth = parseFloat(APPROX_TIME) / (count * 3 + 100)

  let maxDepth = Math.floor(
    Math.max(
      parseInt(MIN_ITER),
      Math.min(parseInt(MAX_ITER), depth)
    )
  )

  maxDepth *= depthMultiplier

  let g = new Guillotine()

  g.packBottomLine = true
  g.shuffle = 0
  let minSol = []
  let minVal = area.w * area.w * area.h * area.h

  let maxX, maxY
  for (var i = 0; i < maxDepth; i++) {
    // i/100;

    if (i > 1 && i < 8) {
      rects.sort((a, b) => {
        switch (i) {
          case 2:
          case 3:
            return a.w - b.w
          case 4:
          case 5:
            return a.h - b.h
          default:
            return a.w * a.h - b.w * b.h
        }
      })
    } else if (i >= 8) {
      rects = shuffleArray(rects)
    }

    let solution = g.solve(area, rects, type)
    g.packBottomLine = !g.packBottomLine
    g.shuffle = (i - 5) / maxDepth
    maxX = Math.max(...solution.map((rect) => rect[0] + rect[2]))
    maxY = Math.max(...solution.map((rect) => rect[1] + rect[3]))
    let val = minVal
    switch (type) {
      case 0:
        val = maxX * maxX * maxY
        break
      case 1:
        val = maxX * maxY * maxY
        break
      case 2:
        val = maxX + maxY
        break
      case 3:
        val = maxX * maxY
        break
      default:
        val = minVal
    }

    if (val < minVal) {
      minVal = val
      minSol = solution
    }
  }

  for (let i = 0; i < rects.length; i++) {
    rects[i].x = minSol[i][0]
    rects[i].y = minSol[i][1]
    rects[i].w = minSol[i][2]
    rects[i].h = minSol[i][3]
    rects[i].id = minSol[i][4]
    rects[i].parId = minSol[i][5]
  }

  return rects
}
