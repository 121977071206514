import { Grid, Typography } from '@mui/material';
import '../App.css';
import COLORS from './FortesColors';
export default function SpaceSettingPreview(
  props: {
    docWidth: number,
    docBorder: number, 
    spacing: number,
    heightAvailable: number
  }
) : JSX.Element {
  
  const fontProps = {
    fontFamily: "Montserrat",
    fontSize: 10,
    fill: COLORS.demoFont
  }

  //if(props.heightAvailable < 410) return <></>

  const base_svg_w = 320
  const base_svg_h = 280
  const real_svg_h = props.heightAvailable - 25 // - Typography height
  const ratio = real_svg_h/base_svg_h > 1.3 ? 1.3 : real_svg_h/base_svg_h
  const newVal = (old: number) => {return old*ratio}
  
  if(props.heightAvailable < 200) return <></>

  return(
  <Grid 
    height={newVal(base_svg_h)+25}
    container direction="column" alignItems="center"
    sx={{borderBlock:`1px solid ${COLORS.separator}`, paddingBlockStart:"0px"}}>

    <Typography
      color={COLORS.darkPurple}
      fontSize={"80%"}
      fontWeight={400}
      maxWidth="999px"
      maxHeight="33px"
      width="100%"
      align="center"
    >
      Canvas preview
    </Typography>

    <svg width={newVal(base_svg_w)} height={newVal(base_svg_h)} viewBox={`0 0 ${newVal(base_svg_w)} ${newVal(base_svg_h)}`} fill="white">
        <pattern id="diagonalHatchDemo" width="10" height="10" patternTransform="rotate(-45 0 0)" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="10" style={{"stroke":COLORS.demoSeparator, "strokeWidth":"1"}} />
        </pattern>
        <rect x={newVal(20)} y={newVal(20)} width={newVal(300)} height={newVal(240)} ry="10px" className="demo doc" />
        <rect x={newVal(40)} y={newVal(40)} width={newVal(260)} height={newVal(200)} ry="10px" className="demo area" />
        <rect x={newVal(40)} y={newVal(40)} width={newVal(260)} height={newVal(200)} ry="10px" className="demo margin" />
        <rect x={newVal(60)} y={newVal(60)} width={newVal(100)} height={newVal(50)} ry="10px" className="demo box" />
        <rect x={newVal(180)} y={newVal(60)} width={newVal(100)} height={newVal(50)} ry="10px" className="demo box" />
        <rect x={newVal(60)} y={newVal(130)} width={newVal(100)} height={newVal(50)} ry="10px" className="demo box" />
        <rect x={newVal(180)} y={newVal(130)} width={newVal(100)} height={newVal(50)} ry="10px" className="demo box" />
        <rect x={newVal(40)} y={newVal(200)} width={newVal(260)} height={newVal(40)} ry="10px" className="demo unused"/>
        <text {...fontProps} x={newVal(20)} y={newVal(15)}>{props.docWidth}mm</text>
        <text {...fontProps} x={newVal(40)} y={newVal(35)}>{props.docBorder}mm</text>
        <text {...fontProps} x={newVal(130)} y={newVal(124)}>{props.spacing}mm</text>
        <text {...fontProps} x={newVal(60)} y={newVal(54)}>{props.spacing}mm</text>
        <text {...fontProps} x={-newVal(180)} y={newVal(54)} transform="rotate(-90)">{props.spacing}mm</text>
        <text {...fontProps} x={newVal(140)} y={newVal(225)}>Unused space</text>
    </svg>
  </Grid>)
}