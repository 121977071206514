import { useState, useEffect, useDebugValue } from "react";

// Hook
const useWindowSize = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<{width: number | undefined, height: number | undefined}>({
        width: undefined,
        height: undefined
    });

    function handleResize() {
      // Set window width/height to state
      setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
      });
    }

    useEffect(() => {
        // only execute all the code below in client side
        if (typeof window !== "undefined") {
            // Handler to call on window resize


            // Add event listener
            window.addEventListener("resize", handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();

            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []); // Empty array ensures that effect is only run on mount
    if(windowSize.height === undefined) setWindowSize({width: windowSize.width, height: 0})
    if(windowSize.width === undefined) setWindowSize({width: 0, height: windowSize.height})
    return { 
      height: windowSize.height === undefined ? 0 : windowSize.height,
      width: windowSize.width === undefined ? 0 : windowSize.width
    }
};


  



export default useWindowSize;