import { Box, Typography } from "@mui/material";
import { Area, SolutionRect } from "../Models";
import COLORS from "../FortesColors";

export default function DetailBox(props: {
  rect: SolutionRect
  allBox: Area
}): JSX.Element {
  const all = props.allBox.h * props.allBox.w;
  const my = (props.rect?.w || 0) * (props.rect?.h || 0);
  const used = (my / all) * 100;
  const typographySx = {
    paddingRight: "20px",
    color: COLORS.darkPurple,
    fontSize: "100%",
    fontWeight: 600,
    maxWidth: "200px",
    maxHeight: "50px"
  }

return (
  <div
  style={{
    margin: "20px",
    width: 400,
    background: "white",
  }}>
  
  <Typography {...typographySx}>
    Selected object detail
  </Typography>


            <Cell key_="Identifier:" value={`${props.rect.id}`}/>
         
            <Cell key_="X:" value={`${props.rect.x} units`}/>
            <Cell key_="Y:" value={`${props.rect.y} units`}/>

            <Cell key_="Width:" value={`${props.rect.w} units`}/>
     
            <Cell key_="Height:" value={`${props.rect.h} units`}/>
      
            <Cell key_="Object size:" value={`${my} units`}/>
      
            <Cell key_="Cut-out area usage:" value={`${used.toFixed(2)} %`}/>
            <Cell key_="Price:" value={`${props.rect.price}`} />
   

</div>
)
}

function Cell(props: {
  key_: string,
  value: string,
}) : JSX.Element {

  const sx = {
    paddingRight: "20px",
    color: COLORS.darkPurple,
    fontSize: "90%",
    fontWeight: 500,
    maxWidth: "200px",
    maxHeight: "50px"
  }

  return (
  <Box 
    display="flex" justifyContent="space-between"
    sx={{paddingBlock:"10px", borderBlockEnd:`1px solid ${COLORS.separator}`}}
  >
    <Typography {...sx} >
      {props.key_}
    </Typography>

    <Typography {...sx} >
          {props.value}
    </Typography>
  </Box>
  )
}