import { ChangeEvent } from "react"
import { FortesNumberFieldBasic } from "../FortesInputs/FortesFields"
import { Box, Grid } from "@mui/material"
import SpaceSettingPreview from "../SpaceSettingsPreview"
import DrawerHeader from "./DrawerHeader"

export default function SpaceDrawer(props: {
  docW: number,
  handleDocWChange: (newVal: number) => void,
  spacing: number,
  handleSpacingChange: (newVal: number) => void,
  docBorder: number,
  handleDocBorderChange: (newVal: number) => void,
  price: number,
  handlePriceChange: (newVal: number) => void,
  onClose: () => void,
  onReload: () => void,
  drawerHeight: number
}) : JSX.Element {
  return (<Box display="flex" flexDirection="column" height="100%">
  <DrawerHeader title="Document" onClose={props.onClose} onReload={props.onReload}/>

  <Grid 
    container direction="column" sx={{paddingInlineEnd: "0px"}}
    justifyContent="space-between"
    height="100%" style={{flexGrow: 1}}
  >
    <Box>
      <Box sx={{margin:"20px 20px 0px 20px"}}>
        <FortesNumberFieldBasic 
          label={"Working area width (mm)"}
          initalValue={props.docW}
          width = "Large"
          onChange={
            (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => props.handleDocWChange(Number(e.target.value))
          }
        />
      </Box>

      <Box sx={{margin:"20px 20px 0px 20px"}}>
        <FortesNumberFieldBasic 
          label={"Spaces (mm)"}
          initalValue={props.spacing}
          width = "Large"
          onChange={
            (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => props.handleSpacingChange(Number(e.target.value))
          }
        />
      </Box>

      <Box sx={{margin:"20px 20px 0px 20px"}}>
        <FortesNumberFieldBasic 
          label={"Border (mm)"}
          initalValue={props.docBorder}
          width = "Large"
          onChange={
            (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => props.handleDocBorderChange(Number(e.target.value))
          }
        />
      </Box>

      <Box sx={{margin:"20px 20px 0px 20px"}}>
        <FortesNumberFieldBasic 
          label={"Base price (for 1 unit²)"}
          initalValue={props.price}
          width = "Large"
          onChange={
            (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => props.handlePriceChange(Number(e.target.value))
          }
        />
      </Box>
    </Box>
    <Box>
      <SpaceSettingPreview
        heightAvailable = {props.drawerHeight - 405 - 62 - 40} // 405: form h, 62: header h, 40: white space
        docWidth={props.docW}
        docBorder={props.docBorder}
        spacing={props.spacing}
      />
    </Box>

  </Grid>
  </Box>)
}