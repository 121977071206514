import { Box, Grid } from "@mui/material";
import { PackedRect } from "./Models";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ChangeEvent, useState } from "react";
import '../App.css';
import {FortesButton} from "./FortesInputs/FortesButtons";
import COLORS from "./FortesColors";
import { FortesNumberFieldAdvanced, FortesNumberFieldBasic, FortesTextField } from "./FortesInputs/FortesFields";


export default function RectComp(props: {
  key: number,
  id: string;
  w: number;
  h: number;
  cnt: number;
  isTechnicalDrawing: boolean;
  onRemove: () => void;
  onChange: (ibj: PackedRect) => void;
}): JSX.Element {
  const [up, Update] = useState(false)
  const [collapsed, setCollapse] = useState(true)
  /*this.state = {
          id: props.id,
          cnt: props.cnt,
          w: props.w,
          h: props.h
      };*/
  /*this.handleChangeH = this.handleChangeH.bind(this);
      this.handleChangeW = this.handleChangeW.bind(this);
      this.handleChangeCnt = this.handleChangeCnt.bind(this);
      this.handleChangeId = this.handleChangeId.bind(this);
      this.handleRemove = this.handleRemove.bind(this);*/

  /*componentDidUpdate() {
      props.change(this.state.id, this.state.w, this.state.h, this.state.cnt);
  }*/

  const handleChangeId = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.onChange({
      id: e.target.value,
      w: props.w,
      h: props.h,
      cnt: props.cnt,
      isTechnicalDrawing: props.isTechnicalDrawing,
      key: props.key
    });
    Update(!up)
  };
  const handleChangeW = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.onChange({
      id: props.id,
      w: Number(e.target.value),
      h: props.h,
      cnt: props.cnt,
      isTechnicalDrawing: props.isTechnicalDrawing,
      key: props.key
    });    
    Update(!up)
  };
  const handleChangeH = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.onChange({
      id: props.id,
      w: props.w,
      h: Number(e.target.value),
      cnt: props.cnt,
      isTechnicalDrawing: props.isTechnicalDrawing,
      key: props.key
    });
    Update(!up)
  };
  const handleChangeCnt = (new_: number) => {
    props.onChange({
      id: props.id,
      w: props.w,
      h: props.h,
      cnt: new_,
      isTechnicalDrawing: props.isTechnicalDrawing,
      key: props.key
    });
    Update(!up)
  };

  const handleRemove = () => {
    props.onRemove();
    Update(!up)
  };

  return (<Box sx={{borderBottom:`1px solid ${COLORS.separator}`, backgroundColor: !collapsed ? COLORS.backgroundBlue : "white"}}>
    <Grid
      container
      height={"80px"}
      direction="row"
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      {collapsed ? 
        <FortesButton width="40px" height="40px" onClick={() => {setCollapse(false)}}>
          <KeyboardArrowDownRoundedIcon/>
        </FortesButton>
      : 
        <FortesButton width="40px" height="40px" onClick={() => {setCollapse(true)}}>
          <KeyboardArrowRightRoundedIcon/>
        </FortesButton>
      }

      <FortesTextField
        initalValue={props.id}
        enabled={!props.isTechnicalDrawing}
        onChange={handleChangeId}
      />

      <FortesButton width="40px" height="40px" onClick={handleRemove}>
        <DeleteOutlineOutlinedIcon/>
      </FortesButton>
    </Grid>

    {!collapsed && <>
      <Grid container direction="row" justifyContent="space-between" sx={{margin:"20px 20px 30px 20px"}} width="410px">
      <FortesNumberFieldBasic 
          label={"Width"}
          initalValue={props.w}
          onChange={handleChangeW}
        />
        <FortesNumberFieldBasic 
          label={"Height"}
          initalValue={props.h}
          onChange={handleChangeH}
        />
        <FortesNumberFieldAdvanced
          label={"Count"}
          initalValue={props.cnt}
          enabled={true}
          onChange={handleChangeCnt}
        />
      </Grid>
    </>}

    </Box>);
}
