import { useState } from "react";
import { Drawing, PackedRect } from "../Models";
import RectComp from "../RectComp";
import { Box, Collapse, Dialog, DialogActions, DialogContent, List, ListItemButton, ListItemIcon, Typography } from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import '../../App.css';
import { FortesButtonHighlighted } from "../FortesInputs/FortesButtons";
import COLORS from "../FortesColors";
import DrawerHeader from "./DrawerHeader";

export default function RectDrawer(props: {
  rects: Array<PackedRect>
  onChange: (rects: Array<PackedRect>) => void
  onClose: () => void,
  onRecalculate: () => void,
  maxHeight: number
}): JSX.Element {
  const [up, Update] = useState(false)

  const scrollbarSX = {"&::-webkit-scrollbar, & *::-webkit-scrollbar":{
    backgroundColor: "#0000",
    width:"5px"
    },
    "&::-webkit-scrollbar-track-piece": {
        backgroundColor:"#0000",
        border:"1px solid #dfe0f2",
        borderRight:"0px",
        borderRadius:8,
    },
    "&::-webkit-scrollbar-track-piece:end " :{
        marginBottom:"25px",
    },
    "&::-webkit-scrollbar-track-piece:start " :{
        marginTop:"25px",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        borderRadius: 8,
        backgroundColor: "#1a0549",
        minHeight: 24,
        border: "0px solid white",
        height: '10px',
    },
    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
        backgroundColor: "#1a0549",
    },
    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
        backgroundColor: "#1a0549",
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#1a0549",
    }
  }

  const genericDrawings = [
    {
      name: "Generic kiosk",
      w: 102,
      h: 198,
    },
    {
      name: "Large kiosk",
      w: 204,
      h: 396,
    },
    {
      name: "Horizontal generic kiosk",
      w: 198,
      h: 102,
    },
    {
      name: "Outside kiosk",
      w: 100,
      h: 50,
    },
    {
      name: "Square kiosk",
      w: 40,
      h: 40,
    },
    {
      name: "Tablet schema",
      w: 80,
      h: 50,
    },
  ];

  /*const [myDrawings, setMyDrawings] = useState<Array<Drawing>>([

  ]); *///is a state for loading drawings from other source in the future
  const [choosingDrawing, setChoosingDrawing] = useState(false);
  const [openMyDrawings, setOpenMyDrawings] = useState(false);
  const [openGenericDrawings, setOpenGenericDrawings] = useState(true);

  //this.add = this.add.bind(this);
  //this.remove = this.remove.bind(this);
  //this.change = this.change.bind(this);
  //this.addDrawing = this.addDrawing.bind(this);
  //this.openDrawingMenu = this.openDrawingMenu.bind(this);

  const remove = (index: number) => {
    var arr = props.rects;
    arr.splice(index, 1);
    props.onChange(arr);
    Update(!up)
  };

  const change = (
    index: number,
    id: string,
    w: number,
    h: number,
    cnt: number
  ) => {
    var arr = props.rects;
    if (
      arr[index].id !== id ||
      arr[index].w !== w ||
      arr[index].h !== h ||
      arr[index].cnt !== cnt
    ) {
      arr[index].id = id;
      arr[index].w = w;
      arr[index].h = h;
      arr[index].cnt = cnt;
      props.onChange(arr);
    }
    Update(!up)
  };

  const add = () => {
    var arr = props.rects;
    arr.push({
      id: "R" + arr.length,
      w: 200,
      h: 200,
      cnt: 1,
      key: Date.now(),
      isTechnicalDrawing: false
    });
    props.onChange(arr);
    Update(!up)
  };

  const addDrawing = (r: Drawing) => {
    var arr = props.rects;
    arr.push({
      id: r.name,
      w: r.w,
      h: r.h,
      cnt: 1,
      key: Date.now(),
      isTechnicalDrawing: true
    });
    props.onChange(arr);
    setChoosingDrawing(false);
    Update(!up)
  };

  const openDrawingMenu = () => {
    setChoosingDrawing(true);
  };

  /**
   * Recalculate drawing dim to fit 50x50 icon
   */
  const icoRect = (obj: Drawing) => {
    let pr = 0;
    if (obj.w > obj.h) {
      pr = 40 / obj.w;
    } else {
      pr = 40 / obj.h;
    }
    let w = obj.w * pr;
    let h = obj.h * pr;
    return { x: 5 + (40 - w) / 2, y: 5 + (40 - h) / 2, w: w, h: h };
  };

  let listItems = props.rects.map((item, index) => (
    <RectComp
      key={item.key}
      id={item.id}
      w={item.w}
      h={item.h}
      cnt={item.cnt}
      isTechnicalDrawing={item.isTechnicalDrawing}
      onRemove={() => remove(index)}
      onChange={(obj) => {
        change(index, obj.id, obj.w, obj.h, obj.cnt);
      }}
    />
  ));

  const maxListItemsHeight = props.maxHeight - 72 - 94 // - header height - bottom buttons height

  return (
    <div>
      <DrawerHeader title="Input areas" onClose={props.onClose} onReload={props.onRecalculate}/>
      <Box sx={{borderBlockStart:`1px solid ${COLORS.separator}` , overflow:"auto", ...scrollbarSX,
              maxHeight:`${maxListItemsHeight}px`}}>{listItems}</Box>
      <Box sx={{ marginTop: 2, marginBottom: 2, textAlign: "center"}}>
        <FortesButtonHighlighted
          highlightColor={COLORS.lightPurple}
          onClick={openDrawingMenu}
          icon={<AddBoxOutlinedIcon/>}
        >
          Add template
        </FortesButtonHighlighted>
        &nbsp;
        <FortesButtonHighlighted 
          icon={<AddOutlinedIcon />} 
          highlightColor={COLORS.lightPurple}
          onClick={add}
        >
          Add custom
        </FortesButtonHighlighted>
      </Box>

      <Dialog
        open={choosingDrawing}
        onClose={() => {
          setChoosingDrawing(false);
        }}
      >
        <Typography
          color={COLORS.darkPurple}
          fontSize={"125%"}
          marginBlockStart="10px"
          align="center"
          fontWeight={600}
          maxWidth="999px"
          maxHeight="33px"
        >
          Add technical drawing
        </Typography>
        <DialogContent sx={{ width: "400px", maxHeight: "600px", color: COLORS.darkPurple}}>
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
          >
            <ListItemButton
              onClick={() => {
                setOpenGenericDrawings(!openGenericDrawings);
              }}
            >
              <ListItemIcon>
                <FolderSpecialIcon sx={{color: COLORS.darkPurple}}/>
              </ListItemIcon>
              <ListItemText primary="Generic drawings" />
              {openGenericDrawings ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openGenericDrawings} timeout="auto" unmountOnExit>
              {genericDrawings.map((drawing, i) => {
                let ico = icoRect(drawing);
                return (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    key={i}
                    onClick={() => {
                      addDrawing(drawing);
                    }}
                  >
                    <ListItemIcon>
                      <svg viewBox="0 0 50 50" style={{ marginRight: "10px" }}>
                        <rect
                          x={0}
                          y={0}
                          width={50}
                          height={50}
                          className="demo doc"
                        />
                        <rect
                          x={ico.x}
                          y={ico.y}
                          width={ico.w}
                          height={ico.h}
                          className="demo rect"
                        />
                      </svg>
                    </ListItemIcon>
                    <ListItemText
                      primary={drawing.name}
                      secondary={drawing.w + " × " + drawing.h}
                    />
                  </ListItemButton>
                );
              })}
            </Collapse>

            <ListItemButton
              onClick={() => {
                setOpenMyDrawings(!openMyDrawings);
              }}
            >
              <ListItemIcon>
                <FolderSharedIcon sx={{color: COLORS.darkPurple}}/>
              </ListItemIcon>
              <ListItemText primary="My personal drawings" />
              {openMyDrawings ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openMyDrawings} timeout="auto" unmountOnExit>
              {/*{myDrawings.map((drawing, i) => {
                let ico = icoRect(drawing);
                return (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    key={i}
                    onClick={() => {
                      addDrawing(drawing);
                    }}
                  >
                    <ListItemIcon>
                      <svg viewBox="0 0 50 50" style={{ marginRight: "10px" }}>
                        <rect
                          x={0}
                          y={0}
                          width={50}
                          height={50}
                          className="demo doc"
                        />
                        <rect
                          x={ico.x}
                          y={ico.y}
                          width={ico.w}
                          height={ico.h}
                          className="demo rect"
                        />
                      </svg>
                    </ListItemIcon>
                    <ListItemText
                      primary={drawing.name}
                      secondary={drawing.w + " × " + drawing.h}
                    />
                  </ListItemButton>
                );
              })}*/}
              <Typography
              sx={{
                width: "100%",
                display:"flex",
                alignItems:"center",
                justifyContent:"center"
              }}
              fontSize={13}
              color="#7b7b7b"
              >
                ...in development...
              </Typography>
            </Collapse>
          </List>
        </DialogContent>
        <DialogActions>
          <FortesButtonHighlighted
            highlightColor="white"
            onClick={() => {
              setChoosingDrawing(false);
            }}
          >
            Cancel
          </FortesButtonHighlighted>
        </DialogActions>
      </Dialog>
    </div>
  );
}
