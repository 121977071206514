import ColumnRowOptimizer from "../algorithms/columnRowOptimizer";
import GuillotineArea from "../algorithms/guillotine_area";
import GuillotineAvg from "../algorithms/guillotine_avg";
import GuillotineBruteforce10Area from "../algorithms/guillotine_bruteforce10_area";
import GuillotineBruteforce10YMin from "../algorithms/guillotine_bruteforce10_ymin";
import GuillotineBruteforce100YMin from "../algorithms/guillotine_bruteforce100_ymin";
//import ExampleAlgorithm from "../algorithms/example";
//import GuillotineGenericBAF from "../algorithms/guillotine_baf";
//import GuillotineGenericBLSF from "../algorithms/guillotine_blsf";
//import GuillotineGenericBSSF from "../algorithms/guillotine_bssf";
//import GuillotineXmin from "../algorithms/guillotine_xmin";
//import GuillotineYMin from "../algorithms/guillotine_ymin";
//import RBP_BAF from "../algorithms/rbp_baf";
//import RBP_BLR from "../algorithms/rbp_blr";
//import RBP_BLSF from "../algorithms/rbp_blsf";
//import RBP_BSSF from "../algorithms/rbp_bssf";
//import RBP_CPR from "../algorithms/rbp_cpr";
import { Area, SolutionRect } from "./Models";

export const algorithms : Array<{
  name: string,
  desc: string,
  func: (area: Area, rects: Array<SolutionRect>) => Array<SolutionRect> | null
}> = [
  { name: "ColumnRowOptimizer", desc: "Column Row Optimizer (1 type only)", func: ColumnRowOptimizer},
  { name: "GuillotineArea", desc: "Guillotine (Upgrade): area" , func: GuillotineArea},
  { name: "GuillotineAvg", desc: "Guillotine (Upgrade): avg" , func: GuillotineAvg},
  {
    name: "GuillotineBruteforce10Area",
    desc: "10xGuillotine (Upgrade): area",
    func: GuillotineBruteforce10Area },
  {
    name: "GuillotineBruteforce10YMin",
    desc: "10xGuillotine (Upgrade): yMin",
    func: GuillotineBruteforce10YMin},
  {
    name: "GuillotineBruteforce100YMin",
    desc: "100xGuillotine (Upgrade): yMin",
    func: GuillotineBruteforce100YMin},
  //{ name : "Example", desc: "Example", func: ExampleAlgorithm },
  //{ name: "GuillotineGenericBAF", desc: "Generic Guillotine: Best Area Fit" , func: GuillotineGenericBAF},
  /*{
    name: "GuillotineGenericBLSF",
    desc: "Generic Guillotine: Best Long Side Fit",
    func: GuillotineGenericBLSF},*/
  /*{
    name: "GuillotineGenericBSSF",
    desc: "Generic Guillotine: Best Short Side Fit",
    func: GuillotineGenericBSSF},*/
  //{ name: "GuillotineXmin", desc: "Guillotine (Upgrade): xMin" , func: GuillotineXmin},
  //{ name: "GuillotineYMin", desc: "Guillotine (Upgrade): yMin" , func: GuillotineYMin },
  /*{ name: "RBP_BAF", desc: "RBP: Best Area Fit" , func: RBP_BAF },*/
  /*{ name: "RBP_BLR", desc: "RBP: Bottom Left Rule" , func: RBP_BLR},*/
  /*{ name: "RBP_BLSF", desc: "RBP: Best Long Side Fit" , func: RBP_BLSF},*/
  /*{ name: "RBP_BSSF", desc: "RBP: Best Short Side Fit" , func: RBP_BSSF},*/
  /*{ name: "RBP_CPR", desc: "RBP: Contact Point Rule" , func: RBP_CPR},*/
];