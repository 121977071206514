import { Orientation } from "./Models";
import '../App.css';
import { ViewPort } from "react-zoomable-ui";


export default function Ruler(props: {
  orientation: Orientation,
  viewPort: ViewPort,
  docWidth: number,
  docHeight: number,
  drawerOpened?: boolean
}): JSX.Element {

  let factor = props.docWidth / props.viewPort.containerWidth;
  let rulerLinesH = [];
  let sc = 10;
  if (props.viewPort?.zoomFactor > 0.45)
    sc = 5;
  if (props.viewPort?.zoomFactor > 1)
    sc = 2;
  for (let i = 0; i < Math.floor(props.docWidth / sc) + 1; i++) {
    rulerLinesH.push(i * sc);
  }
  let rulerLinesV = [];
  for (let i = 0; i < Math.floor(props.docHeight / sc) + 1; i++) {
    rulerLinesV.push(i * sc);
  }
  if (props.viewPort?.zoomFactor <= 0.3)
    sc = 20;
  if (props.viewPort?.zoomFactor <= 0.15)
    sc = 40;
  const classOrientation = props.orientation === Orientation.VERTICAL ? `${props.orientation} ${props.drawerOpened}` : props.orientation
  return(
    <div className={"ruler " + classOrientation + " sc-" + sc}>
        { props.orientation === "horizontal" && <div className="slider" style={{
            left: (props.viewPort?.left * -1 * props.viewPort?.zoomFactor) || 0,
            width: (props.docWidth * props.viewPort?.zoomFactor / factor) || 0
        }}>
            <svg viewBox={"0 0 " + props.docWidth + " 20"}>
                {
                    rulerLinesH.map((line, i) => 
                        <line key={i} x1={line} x2={line} y1={0} y2={20} stroke='black' strokeWidth={(0.1 / props.viewPort?.zoomFactor) || 0.1} />
                    )
                }
            </svg>
            <div className="nums horizontal">{ rulerLinesH.map((line, i) => <span key={i} className="num" style={{left: "calc(" + ((line / props.docWidth * 100) || 0) + "% - 10px)" }}>{line}</span>) }</div>
        </div> }
        { props.orientation === "vertical" && <div className="slider" style={{
            top: (props.viewPort?.top * -1 * props.viewPort?.zoomFactor) || 0,
            height: (props.docHeight * props.viewPort?.zoomFactor / factor) || 0
        }}>
            <svg viewBox={"0 0 20 " + props.docHeight}>
                {
                    rulerLinesV.map((line, i) => 
                        <line key={i} x1={0} x2={20} y1={line} y2={line} stroke='black' strokeWidth={(0.1 / props.viewPort?.zoomFactor) || 0.1} />
                    )
                }
            </svg>
            <div className="nums vertical">{ rulerLinesV.map((line, i) => <span key={i} className="num" style={{top: "calc(" + ((line / props.docHeight * 100) || 0) + "% - 13px)" }}>{line}</span>) }</div>
        </div> }
    </div>
  );

}