const COLORS = {
  separator: "#e0ddf2",
  lightPurple: "#edf2f6",
  darkPurple: "#250048",
  backgroundBlue:"#f6fbff",
  demoFont: "#8d749e",
  demoSeparator: "#dfd8e6",
  demoLightFill: "#f4f1f6",
  demoDarkFill: "#e9e5ee"
}
export default COLORS