export interface Area {
  w: number;
  h: number;
  x?: number;
  y?: number;
}

export interface BoxBoundary {
  w: number;
  h: number;
  x: number;
  y: number;
}

export interface PackedRect {
  id: string;
  w: number;
  h: number;
  cnt: number;
  isTechnicalDrawing: boolean;
  key: number;
}

export interface Drawing {
  name: string;
  w: number;
  h: number;
}

export interface SolutionRect {
  id: string;
  pairId: string;
  w: number;
  h: number;
  x: number;
  y: number;
  price?: number
}

export interface ComputedData {
  allBox: BoxBoundary;
  boundingBox: BoxBoundary;
  spacing: number;
  price: number;
  allboxW: number;
  allboxH: number;
  rects: Array<SolutionRect>;
  solvedRects: Array<SolutionRect>;
  totalArea: number;
  usedArea: number;
  wasted: number;
  priceStats: {[key: string]: { one: number, all: number, cnt: number }};
}

export enum Orientation {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

export interface WorkerMsgIn {
  docWidth: number;
  docHeight: number;
  spacing: number;
  docBorder: number;
  rects: Array<PackedRect>;
  price: number;
  selectedAlgos: Array<string>;
}

export interface Solution {
  allArea: Area;
  computedData: ComputedData;
  boundingBox?: BoxBoundary;
  allBox: BoxBoundary;
  bestAlgo: string
}
