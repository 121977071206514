import { RefObject, useCallback, useEffect, useState } from "react";
import { Area, ComputedData, SolutionRect } from "./Models";
import '../App.css';

export default function ForbinDocument(props: {
  allArea: Area;
  computed: ComputedData | undefined;
  onRectSelected: (selected: SolutionRect | undefined) => void;
  onSelectedRectChange: (args: {
    origId: string,
    newX: number,
    newY: number,
    newH: number,
    newW: number 
  }) => void;
  svgRef: RefObject<SVGSVGElement>
}): JSX.Element {
  const [selectedRect, setSelectedRect] = useState<SolutionRect | undefined>(undefined)

  const keydownHandler = useCallback((event: KeyboardEvent) => {
    if (event.target === document.body && selectedRect) {
      if (event.code === "KeyR") {
        props.onSelectedRectChange({
          origId: selectedRect.id,
          newX: selectedRect.x,
          newY: selectedRect.y,
          newW: selectedRect.h,
          newH: selectedRect.w,
        });
      } else {
        let x = 0;
        let y = 0;
        if (event.code === "ArrowLeft" || event.code === "KeyA") x = -1;
        if (event.code === "ArrowRight" || event.code === "KeyD") x = +1;
        if (event.code === "ArrowUp" || event.code === "KeyW") y = -1;
        if (event.code === "ArrowDown" || event.code === "KeyS") y = +1;

        if (event.shiftKey) {
          x *= 10;
          y *= 10;
        }

        if (x !== 0 || y !== 0) {
          props.onSelectedRectChange({
            origId: selectedRect.id,
            newX: selectedRect.x + x,
            newY: selectedRect.y + y,
            newW: selectedRect.w,
            newH: selectedRect.h,
          });
        }
      }
    }
  }, [props, selectedRect])

  const reopenSolutionRect = () => {
    if(selectedRect === undefined) return
    if(props.computed) {
      let id = selectedRect.id;
      let r = props.computed.rects.find((x: SolutionRect) => x.id === id)
      if(r === undefined) return

      r.price = props.computed.solvedRects.find((x:SolutionRect) => x.id === id)?.price
      setSelectedRect(r);
      props.onRectSelected(r)
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keydownHandler)
    return () => {
      document.removeEventListener("keydown", keydownHandler)
    }
  }, [selectedRect, keydownHandler])

  useEffect(() => {
    reopenSolutionRect()
  })

  const fontProps = {
    fontFamily: "'Montserrat'",
    fontSize: 4,
    fill: "black",
  };

  return (
    <div className="document">
      <svg ref={props.svgRef} viewBox={"0 0 " + props.allArea.w + " " + props.allArea.h}>
        <pattern
          id="diagonalHatch"
          fill="#afe6f388"
          width="10"
          height="10"
          patternTransform="rotate(45 0 0)"
          patternUnits="userSpaceOnUse"
        >
          <rect width="10" height="10" className="boundingBoxox"></rect>
          <line
            x1="0"
            y1="0"
            x2="0"
            y2="10"
            style={{ stroke: "#F008", strokeWidth: "1" }}
          />
        </pattern>
        {props.computed && (
          <rect
            x={props.computed.allBox.x || 0}
            y={props.computed.allBox.y || 0}
            width={props.computed.allboxW || 0}
            height={props.computed.allboxH || 0}
            className="allbox"
          ></rect>
        )}
        {props.computed && (
          <rect
            x={props.computed.boundingBox.x}
            y={props.computed.boundingBox.y}
            width={props.computed.boundingBox.w + props.computed.spacing}
            height={props.computed.boundingBox.h + props.computed.spacing}
            className="boundingBox"
          ></rect>
        )}
        {props.computed && (
          <rect
            x={props.computed.allBox.x }
            y={props.computed.allBox.y || 0}
            width={props.computed.allboxW || 0}
            height={props.computed.allboxH || 0}
            className="allbox2"
          ></rect>
        )}
        {props.computed &&
          props.computed.solvedRects.map((r: SolutionRect, i: number) => (
            <g key={i}>
              <rect
                x={r.x}
                y={r.y}
                width={r.w}
                height={r.h}
                className={"rect" + (selectedRect?.id === r.id || selectedRect === undefined? " active" : "")}
                onClick={() => {
                  if (selectedRect?.id === r.id) {
                    setSelectedRect(undefined);
                    props.onRectSelected(undefined);
                  } else {
                    setSelectedRect(r);
                    props.onRectSelected(r);
                  }
                }}
              ></rect>
              <text {...fontProps} x={r.x + 2} y={r.y + 7}>
                {r.id}
              </text>
              <text {...fontProps} x={r.x + 2} y={r.y + 14}>
                {r.price + ",-"}
              </text>
            </g>
          ))}
        {props.allArea.w && (
          <rect
            width={props.allArea.w}
            height={props.allArea.h}
            className="area"
          ></rect>
        )}
      </svg>
    </div>
  );
}
