export default function solve(area, rects) {
  let width = rects[0].w
  let height = rects[0].h

  for (let i = 0; i < rects.length; i++) {
    if (rects[i].w != width || rects[i].h != height) return null
  }

  let count = rects.length
  let longerSide = Math.max(width, height)
  let shorterSide = Math.min(width, height)
  let spaceAvail = area.w

  let maxPerRow = Math.min(Math.ceil(spaceAvail / longerSide), count)
  let closest = spaceAvail
  let shortCount = 0
  let longCount = 0
  let totalWaste = Infinity
  let tmpLongCount = 0,
    tmpShortCount = 0
  let longestColumn = Infinity
  for (var longIter = 0; longIter < maxPerRow + 1; longIter++) {
    tmpLongCount = longIter
    tmpShortCount = (spaceAvail - longerSide * tmpLongCount) / shorterSide
    if (tmpShortCount < 0) {
      tmpShortCount = 0
      tmpLongCount--
    }

    let subCount = 0
    let shortColLength = 0
    let longColLength = 0

    while (subCount < count) {
      if (shortColLength <= longColLength || tmpShortCount == 0) {
        subCount = subCount + tmpLongCount
        shortColLength += shorterSide
      } else {
        subCount = subCount + tmpShortCount
        longColLength += longerSide
      }
    }

    let tmpLongestColumn = Math.max(shortColLength, longColLength)

    let spaceTaken =
      Math.floor(tmpShortCount) * shorterSide + tmpLongCount * longerSide

    let diff = Math.abs(spaceTaken - spaceAvail)

    if (diff < closest && tmpLongestColumn <= longestColumn) {
      closest = diff
      shortCount = Math.floor(tmpShortCount)
      longCount = tmpLongCount
    }

    if (tmpLongestColumn < longestColumn) {
      longestColumn = tmpLongestColumn
      shortCount = Math.floor(tmpShortCount)
      longCount = tmpLongCount
    }
  }

  let rowCount = shortCount + longCount
  let offset = longCount * longerSide

  let rowIndex = 1
  let collIndex = 0

  // Short row
  let shortLayer = 1
  let longLayer = 1
  let placed = 0
  let shortDone = 0
  let longDone = 0

  while (placed < count) {
    //console.log((shortLayer) * longerSide, (longLayer) * shorterSide);
    // Long row
    if (longLayer > longDone) {
      for (var i = 0; i < longCount; i++) {
        if (placed >= count) break

        rowIndex = longDone
        collIndex = i
        rects[placed].x = collIndex * longerSide
        rects[placed].y = rowIndex * shorterSide
        rects[placed].w = longerSide
        rects[placed].h = shorterSide
        placed++
      }
      longDone++
    }

    // Short row
    if (shortLayer > shortDone) {
      for (var i = 0; i < shortCount; i++) {
        if (placed >= count) break

        rowIndex = shortDone
        collIndex = i
        rects[placed].x = collIndex * shorterSide + offset
        rects[placed].y = rowIndex * longerSide
        rects[placed].w = shorterSide
        rects[placed].h = longerSide
        placed++
      }
      shortDone++
    }

    if (shortLayer == shortDone && longLayer == longDone) {
      if (shortLayer * longerSide >= longLayer * shorterSide)
        longLayer += Math.floor(longerSide / shorterSide)
      else shortLayer++
    }
  }

  return rects
}
