import { Box, Button } from "@mui/material";
import COLORS from "../FortesColors";

export function FortesButton(props: baseProps) : JSX.Element {
  return <FortesButtonBase {...props}/>
}

export function FortesButtonHighlighted(props: {
  highlightColor: string,
  contentColor?: string
} & baseProps
): JSX.Element {
  return <FortesButtonBase {...props} sx_={{backgroundColor: props.highlightColor, color: props.contentColor}}/>
}

export function FortesButtonCheckable(props:{
  checked: boolean
} & baseProps
) : JSX.Element {
  const sx_ = props.checked ? {backgroundColor: COLORS.lightPurple} : {backgroundColor: "white"}

  return <FortesButtonBase {...props} sx_={{...sx_, borderRadius: "14px"}} />
}


interface baseProps {
  onClick: () => void,
  children?: React.ReactNode,
  icon?: React.ReactNode,
  height?: string,
  width?: string,
}

function FortesButtonBase(props: {
  sx_?: any
} & baseProps
) : JSX.Element {
  const height = (props.height === undefined) ? "42px" : props.height
  const iconMargin = (props.children === undefined) ? "0px" : "10px"
  const contentColor = (props.sx_?.color === undefined) ? COLORS.darkPurple : props.sx_.color

  return (
    <Button
    sx = {{
      width: props.width,
      height: height,
      minWidth: "2px",
      minHeight: "2px",
      borderRadius: "999px",
      paddingInline: "10px",
      margin:"10px",
      '&:hover': {
        backgroundColor: COLORS.darkPurple,
        color: "white"
      },
      textTransform: "none",
      ...props.sx_,
      color: contentColor,
    }}
    onClick={props.onClick}
    >
      {props.icon &&
        <Box sx={{
          marginInlineEnd: iconMargin, display:"flex", alignItems:"center"}}
        >{props.icon}</Box>
      }
      {props.children}
    </Button>
  )
}