import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import LoupeOutlinedIcon from '@mui/icons-material/LoupeOutlined';
import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { Area, PackedRect, SolutionRect, Solution, ComputedData, Orientation } from "./Models";
import Ruler from "./Ruler";
import { Space, ViewPort, ViewPortCameraInterface } from "react-zoomable-ui";
import ForbinDocument from "./ForbinDocument";
//import { manualSolve, solveAndFindBest } from "./ForbinResolver";
import RectDrawer from "./Drawers/RectDrawer";
import PriceBox from "./Boxes/PriceBox";
import "../App.css";
import { manualSolve } from "./ForbinResolver";
import COLORS from "./FortesColors";
import SpaceDrawer from "./Drawers/SpaceDrawer";
import { FortesButtonCheckable } from "./FortesInputs/FortesButtons";
import DetailBox from "./Boxes/DetailBox";
import useWindowSize from "../windowSize";
import ForbinHeader from "./ForbinHeader";
import { algorithms } from "./AlgoDict";
import PDFDocument from "pdfkit/js/pdfkit.standalone";
import SVGtoPDF from "svg-to-pdfkit";
import blobStream from "blob-stream";

export default function Forbin(): JSX.Element {
  //miniServer = new MiniServer;

  const [priceBoxOpen, setPriceBoxOpen] = useState(false);
  const [packedRects, setPackedRects] = useState<Array<PackedRect>>([]);
  const [isComputing, setIsComputing] = useState(false);
  const [docWidth, setDocWidth] = useState(300);
  const [docHeight, setDocHeight] = useState(1000);
  const [spacing, setSpacing] = useState(10);
  const [docBorder, setDocBorder] = useState(10);
  const [allArea, setAllArea] = useState<Area>({ w: 0, h: 0, x: 0, y: 0 });
  const [computedData, setcomputedData] = useState<ComputedData | undefined>(undefined);
  const [price, setPrice] = useState(0.01);
  const [camera, setCamera] = useState<ViewPortCameraInterface | undefined>(undefined);
  const [viewPort, setViewPort] = useState<ViewPort | undefined>(undefined);
  const [cursorX, setCursorX] = useState(0);
  const [cursorY, setCursorY] = useState(0);
  const [cursorPosX, setCursorPosX] = useState("0");
  const [cursorPosY, setCursorPosY] = useState("0");
  const [selectedRect, setSelectedRect] = useState<SolutionRect | undefined>(undefined);
  const [detailBoxOpen, setDetailBoxOpen] = useState<boolean>(false)
  const [rulerActive, setRulerActive] = useState(true);
  const [showSolvingError, setShowSolvingError] = useState(false);
  const [showIneficientSolution, setShowIneficientSolution] = useState(false);
  const [rectDrawerOpened, setRectDrawerOpened] = useState(false)
  const [spaceDrawerOpened, setSpaceDrawerOpened] = useState(false)

  const BASICALGOS = [
    ...algorithms.map(v => v.name).filter((str: string) => (str !== "GuillotineBruteforce100YMin" && str !== "GuillotineBruteforce10YMin" && str !== "GuillotineBruteforce10Area")),
  ]
  const ADVANCEDALGOS = [
    "GuillotineBruteforce100YMin", "GuillotineBruteforce10YMin", "GuillotineBruteforce10Area"
  ]
  const windowSize = useWindowSize()
  const headerHeight = 80
  const sideBarHeight = windowSize.height - headerHeight

  const workerRef = useRef<Worker | null>(null);

  useEffect(() => {
    // Vytvoření pracovníka pouze při prvním renderu
    workerRef.current = new Worker(new URL("./WorkerResolver.ts", import.meta.url), {
      type: "module",
    });

    // Cleanup funkce pro ukončení pracovníka při odpojení komponenty
    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
      }
    };
  }, []); // Prázdné pole znamená, že se efekt spustí pouze jednou

  const docRef = useRef();

  const onPackedRectsCahnge = (rects: Array<PackedRect>) => {
    setPackedRects(rects);
  };

  const recenter = useCallback(() => {
    camera &&
      camera.recenter(
        window.innerWidth/2,
        (window.innerHeight),
        0.35
      );
  }, [camera]);

  useEffect(() => {
    const replyHandler = (e: MessageEvent<Solution | undefined>) => {
      setIsComputing(false);
      setPriceBoxOpen(false);
      setDetailBoxOpen(false)

      console.log("from worker received", e.data);
      if(e.data === undefined) {
        setShowSolvingError(true)
        return
      }
      setDocHeight(e.data.allBox.h + spacing)
      setAllArea({h: e.data.allBox.h + spacing, w: docWidth})
      setcomputedData(e.data.computedData)
      recenter()
      const usedSpace = (e.data.computedData.usedArea / e.data.computedData.totalArea)
      if(usedSpace < 0.5 && e.data.computedData.rects.length > 0 && (e.data.bestAlgo in BASICALGOS)) {
        setShowIneficientSolution(true)
      }
    }

    if(workerRef.current) {
      workerRef.current.addEventListener("message", replyHandler);
    }
    return () => {
      if(workerRef.current) {
        workerRef.current?.removeEventListener("message", replyHandler)
      }
    }
  }, [workerRef, spacing, docWidth, packedRects, recenter])
  //not dependent on bASICALGOS since its constant

  const solve = (algos: Array<string> = BASICALGOS) => {
    console.log("solving w", algos)
    if(!workerRef.current) {
      console.log("Worker is null")
      return
    }
    setIsComputing(true);
    workerRef.current.postMessage({
      //api: this.props.api,
      docWidth: docWidth,
      docHeight: getMaxDocHeight(packedRects, docBorder, spacing),
      spacing: spacing,
      docBorder: docBorder,
      rects: packedRects,
      price: price,
      selectedAlgos: algos,
    });
  }

  const getMaxDocHeight = (rects: Array<PackedRect>, border: number, space: number) => {
    let maxH = 2*border + space
    rects.forEach((rect: PackedRect) => {
      maxH += ((rect.h > rect.w ? rect.h : rect.w) + space) * rect.cnt
    })
    return maxH
  }

  const mouseUpdate = (event: MouseEvent<HTMLDivElement>) => {
    //this.setState({cursorX: event.clientX, cursorY: event.clientY});
    if (viewPort === undefined) return;

    let pos = viewPort.translateClientXYCoordinatesToVirtualSpace(
      event.clientX,
      event.clientY
    );
    let factor = docWidth / viewPort?.containerWidth;
    let percX = (pos.x / docWidth) * factor || 0;
    let percY = (pos.y / docHeight) * factor || 0;
    let valX = Number(percX * docWidth).toFixed(0);
    let valY = Number(percY * docHeight).toFixed(0);
    if (event.clientX !== cursorX || event.clientY !== cursorY) {
      setCursorX(event.clientX);
      setCursorY(event.clientY);
      setCursorPosX(valX);
      setCursorPosY(valY);
    }
  };

  useEffect(() => {
    solve();
  }, []); //Dependencies is empty, so solve()
          //is called (by this effect) only on mount

  const svgRef = useRef<SVGSVGElement>(null);
  const download = () => {
    if (!svgRef.current || !computedData) return

    const doc = new PDFDocument({ size: "A4", layout: "landscape" });
    const scaleFactor = Math.min(doc.page.width / computedData.allBox.w, doc.page.height / computedData.allBox.h);
    const scaledHeight = docHeight * scaleFactor;

    SVGtoPDF(doc, svgRef.current, 0, 0, { useCSS: true, preserveAspectRatio: "xMidYMid meet", height: scaledHeight });
    doc.end();

    const stream = doc.pipe(blobStream())
    stream.on('finish', () => {
      const blob = stream.toBlob();
      const url = window.URL.createObjectURL(blob);
      const alink = document.createElement("a");
      alink.download = "forbinSolution.pdf"
      alink.href = url;
      alink.click();
      window.URL.revokeObjectURL(url);
    })
  }

  return (<>
  <Grid
    sx={{
      zIndex:12, backgroundColor:"white",
      borderBlockEnd: `1px solid ${COLORS.separator}`,
      position:"relative"
    }}
  >
    <ForbinHeader height={headerHeight} exporFn={download}/>
  </Grid>
  <Grid container direction={"row"} justifyContent="space-between">
    <Box display="flex" flexDirection="row"> {/* In a box so Box (1st child) and Grid (2nd child) are on the 
              left and box w/ pricing and detail on the right*/}
      <Box display="flex" flexDirection="row" sx={{zIndex:11}}>
        <Grid 
          container 
          sx={{backgroundColor:"white", zIndex:10, borderRight: `1px solid ${COLORS.separator}`}}
          height={`${sideBarHeight}px`} 
          width={"62px"} 
          direction={"column"}
          alignItems={"center"}
        >
          <FortesButtonCheckable
            width={"42px"}
            height={"42px"}
            checked={spaceDrawerOpened}
            icon={<SettingsOutlinedIcon fontSize="medium"/>}
            onClick={() => {
              setRectDrawerOpened(false)
              setSpaceDrawerOpened(!spaceDrawerOpened)
            }}
          />
          <FortesButtonCheckable
            width={"42px"}
            height={"42px"}
            checked={rectDrawerOpened}
            icon={<GridViewOutlinedIcon fontSize="medium"/>}
            onClick={() => {
              setSpaceDrawerOpened(false)
              setRectDrawerOpened(!rectDrawerOpened);
            }}
          />

        </Grid>

        {rectDrawerOpened && 
          <Grid
            height={`${sideBarHeight}px`}
            width={"450px"}
            sx={{backgroundColor:"white", zIndex:10, borderRight: `1px solid ${COLORS.separator}`}}
          >
            <Box>
              <RectDrawer rects={packedRects} onChange={onPackedRectsCahnge} 
                          onClose={() => setRectDrawerOpened(false)}
                          onRecalculate={() => solve()} 
                          maxHeight={sideBarHeight}/>
            </Box>
          </Grid>
        }

        {spaceDrawerOpened &&
          <Grid
            height={`${sideBarHeight}px`}
            width={"450px"}
            sx={{backgroundColor:"white", zIndex:10, borderRight: `1px solid ${COLORS.separator}`}}
          >
            <SpaceDrawer
              drawerHeight={sideBarHeight}
              docW={docWidth}
              spacing={spacing}
              docBorder={docBorder}
              price={price}
              handleDocBorderChange={setDocBorder}
              handleDocWChange={setDocWidth}
              handlePriceChange={setPrice}
              handleSpacingChange={setSpacing}
              onClose={() => setSpaceDrawerOpened(false)}
              onReload={() => solve()}
            />
          </Grid>
        }
      </Box>
      
      <Grid>
          <div onMouseMove={mouseUpdate} style={{flexGrow: "1"}}>
            {rulerActive && viewPort && (
              <>
                <Ruler
                  orientation={Orientation.VERTICAL}
                  viewPort={viewPort}
                  docWidth={docWidth}
                  docHeight={docHeight}
                  drawerOpened={rectDrawerOpened || spaceDrawerOpened}
                />
                <Ruler
                  orientation={Orientation.HORIZONTAL}
                  viewPort={viewPort}
                  docWidth={docWidth}
                  docHeight={docHeight}
                />
              </>
            )}
            {rulerActive && (
              <>
                <div className="guideline horizontal" style={{ top: cursorY, zIndex:10 }}>
                  <span className="pos x">{cursorPosY}</span>
                </div>
                <div className="guideline vertical" style={{ left: cursorX, zIndex:10 }}>
                  <span className="pos y">{cursorPosX}</span>
                </div>
              </>
            )}


            <Space
              onCreate={(viewPort) => {
                setCamera(viewPort.camera);
                recenter();
              }}
              onUpdated={(props) => {
                if (props.zoomFactor < 0.1)
                  props.camera.recenter(props.centerX, props.centerY, 0.1);
                setViewPort(props);
              }}
              style={{ 
                zIndex: 0
              }}
            >
              <Box className="document" ref={docRef}>
                <ForbinDocument
                  allArea={allArea}
                  computed={computedData}
                  onRectSelected={(selected) => {
                    setSelectedRect(selected)
                    if(selectedRect === undefined && selected !== undefined) {
                      setDetailBoxOpen(true)
                      setPriceBoxOpen(false)
                    }
                    else if(selected === undefined) setDetailBoxOpen(false)
                  }}
                  onSelectedRectChange ={(args: {
                    origId: string,
                    newX: number,
                    newY: number,
                    newH: number,
                    newW: number 
                  }) => {
                    let mutated : Array<SolutionRect> | undefined= structuredClone(computedData?.solvedRects);
                    if (mutated === undefined) return
                    let elem = mutated.find((x: SolutionRect) => x.id === args.origId);
                    if (elem === undefined) return

                    const newVals = {
                      newX: args.newX + spacing + docBorder,
                      newY: args.newY + spacing + docBorder,
                      newH: args.newH - spacing,
                      newW: args.newW - spacing
                    }
                    if(
                      (newVals.newX > elem.x && newVals.newX + elem.w + docBorder + spacing > docWidth)  ||
                      (newVals.newY > elem.y && newVals.newY + elem.h + docBorder + spacing > docHeight) ||
                      (newVals.newX < elem.x && newVals.newX - docBorder - spacing < 0)                  ||
                      (newVals.newY < elem.y && newVals.newY - docBorder - spacing < 0)
                    ) return

                    if(newVals.newH !== elem.h && (
                      newVals.newX + newVals.newW + docBorder + spacing > docWidth  ||
                      newVals.newY + newVals.newH + docBorder + spacing > docHeight ||
                      newVals.newX - docBorder - spacing < 0                  ||
                      newVals.newY - docBorder - spacing < 0
                    )) return

                    elem.x = newVals.newX
                    elem.y = newVals.newY
                    elem.w = newVals.newW
                    elem.h = newVals.newH
                    mutated = mutated.map((el: SolutionRect) => {
                      return {
                        id: el.id,
                        pairId: el.pairId,
                        w: el.w + spacing,
                        h: el.h + spacing,
                        x: el.x - spacing - docBorder,
                        y: el.y - spacing - docBorder
                      }
                    })
                    var d = manualSolve(
                      docWidth,
                      docHeight,
                      spacing,
                      docBorder,
                      mutated || [],
                      price,
                    );
                    if (d) {

                      setAllArea(d.allArea)
                      setcomputedData(d.computedData)
                    }
                  }}
                  svgRef={svgRef}
                />
              </Box>
            </Space>            
            <Dialog
                open={!!showSolvingError}
                onClose={() => {
                  setShowSolvingError(false);
                }}
              >
                <DialogTitle>No solution found</DialogTitle>
                <DialogContent>
                  There was a problem while trying to arange the objects using selected
                  Bin Packing Algorithms.
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setShowSolvingError(false);
                    }}
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={showIneficientSolution}
                onClose={() => {
                  setShowIneficientSolution(false);
                }}
              >
                <DialogTitle>Inefficient solution</DialogTitle>
                <DialogContent>
                  We detected that found solution may be inefficient.
                  Do you want to try to find better solution? (Might take longer to complete)
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setShowIneficientSolution(false);
                    }}
                  >
                    no
                  </Button>
                  <Button
                    onClick={() => {
                      solve(ADVANCEDALGOS)
                      setShowIneficientSolution(false);
                    }}
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={!!isComputing}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
      </Grid>
      <Checkbox
        sx={{
          padding: "0px", height:"19px", width:"19px",
          zIndex:"1120", color: COLORS.darkPurple,
          '&.Mui-checked': {
            color: COLORS.darkPurple
          }
        }}
        checked={rulerActive}
        onChange={(ev) => {
          setRulerActive(ev.target.checked);
        }}
      />
    </Box>

    <Box
      display="flex"
      flexDirection="row"
      sx={{zIndex:12}}
    >
      <Box display="flex" flexDirection="column">
        <Box
          height="64px"
          sx={{backgroundColor:"white", borderRadius:"20px 0px 0px 20px", border:`1px solid ${COLORS.separator}`, borderInlineEnd:"0px"}}
        >
          <FortesButtonCheckable
            checked={priceBoxOpen} onClick={()=>{setPriceBoxOpen(!priceBoxOpen); setDetailBoxOpen(false)}}
            icon={<AttachMoneyIcon/>}
          />
        </Box>

        {selectedRect &&
          <Box
            height="64px"
            sx={{backgroundColor:"white", borderRadius:"20px 0px 0px 20px", border:`1px solid ${COLORS.separator}`, borderInlineEnd:"0px"}}
          >
            <FortesButtonCheckable
              checked={detailBoxOpen} onClick={()=>{setDetailBoxOpen(!detailBoxOpen); setPriceBoxOpen(false)}}
              icon={<LoupeOutlinedIcon/>}
            />
          </Box>
        }
      </Box>

      {priceBoxOpen &&
        <Grid 
          height={"1000px"}
          width={"450px"}
          sx={{
            borderInlineStart:`1px solid ${COLORS.separator}`,
            backgroundColor:"white"
          }}
        >
          <PriceBox
            totalArea={computedData?.totalArea ?? 0}
            usedArea={computedData?.usedArea ?? 0}
            wasted={computedData?.wasted ?? 0}
            price={price}
            priceStats={computedData?.priceStats ?? {}}
          />
        </Grid>
      }
      {detailBoxOpen && selectedRect &&
        <Grid 
          height={"1000px"}
          width={"450px"}
          sx={{
            borderInlineStart:`1px solid ${COLORS.separator}`,
            backgroundColor:"white"
          }}
        >
          <DetailBox rect={selectedRect}  allBox={computedData!.allBox}/>
        </Grid>
      }
    </Box>
  </Grid>
</>);
}
