import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { ChangeEvent } from "react";
import COLORS from "../FortesColors";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

export function FortesTextField(props: {
  initalValue: string,
  enabled: boolean,
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
}) : JSX.Element {
  return <FortesBaseField 
    width="300px"
    initalValue={props.initalValue}
    enabled={props.enabled} 
    onChange={props.onChange}
    numberType={false}
  />
}

export function FortesNumberFieldAdvanced(props: {
  label: string,
  initalValue: number,
  enabled: boolean,
  onChange: (new_: number) => void,
}) : JSX.Element {
  return <Box>
    <Typography 
      paddingRight="20px"
      color={COLORS.darkPurple}
      fontSize={"80%"}
      fontWeight={400}
      maxWidth="200px"
      maxHeight="33px"
    >
      {props.label}
    </Typography>
    <Grid container spacing={0.5}>
      <Grid item><NumberButton
        sign="-"
        onClick={() => {props.onChange(props.initalValue - 1)}}
      /></Grid>
      <Grid item><FortesNumberBaseField
        width="ExtraSmall"
        initalValue={props.initalValue}
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => props.onChange(Number(e.target.value))}
      /></Grid>
      <Grid item><NumberButton
        sign="+"
        onClick={() => {if(props.initalValue > 0) props.onChange(props.initalValue + 1)}}
      /></Grid>
    </Grid>
  </Box>
}

export function FortesNumberFieldBasic(props: {
  label: string,
  initalValue: number,
  width?: "Large" | "Small" | "ExtraSmall"
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
}) : JSX.Element {

  return <Box>
    <Typography 
      paddingRight="20px"
      color={COLORS.darkPurple}
      fontSize={"80%"}
      fontWeight={400}
      maxWidth="200px"
      maxHeight="33px"
    >
      {props.label}
    </Typography>
    <FortesNumberBaseField
      width={props.width}
      initalValue={props.initalValue}
      onChange={props.onChange}
    />
  </Box>
}

function NumberButton(props: {
  onClick: () => void,
  sign: "+" | "-"
}): JSX.Element {
  return (
    <Button
      sx={{
        height:"40px",
        minWidth:"1px",
        width:"40px",
        color: COLORS.darkPurple,
        backgroundColor: "white",
        border:`1px solid ${COLORS.separator}`,
        borderRadius:"14px"
      }}
      onClick={props.onClick}
    >
      {props.sign === "+" ? <AddOutlinedIcon/> : <RemoveOutlinedIcon/>}
    </Button>
  )
}

function FortesNumberBaseField(props: {
  initalValue: number,
  width?: "Large" | "Small" | "ExtraSmall"
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
}) : JSX.Element {
  const width = props.width === "Large" ? "410px" :
                props.width === "ExtraSmall" ? "70px" :
                "100px"

  return <FortesBaseField 
    width={width}
    initalValue={`${props.initalValue}`}
    enabled={true} 
    onChange={props.onChange}
    numberType={true}
    sx={{
      "& input[type=number]::-webkit-outer-spin-button": {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
      }
    }}
  />
}

  
function FortesBaseField(props: {
  initalValue: string,
  enabled: boolean,
  width: string,
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  numberType: boolean
  sx? : any
}) : JSX.Element {
  const ownSx = props.sx

  return (
    <TextField
    disabled={!props.enabled}
    value={props.initalValue}
    type={props.numberType ? "number" : ""}
    onChange={props.onChange}
    sx={{
      width:props.width,
      height:"40px",
      backgroundColor: "white",
      border: "1px solid #dfe0f2",
      borderRadius: "14px",
      '& .MuiOutlinedInput-notchedOutline': {
          border: "0px solid black",
      },
      '& .MuiOutlinedInput-root': {
          color: COLORS.darkPurple,
          padding:"0px",
          borderRadius: "14px",
          '&.Mui-focused fieldset': {
            borderColor: '#1a0549',
          },
      },
      '& .MuiOutlinedInput-input': {
          padding:"5px 10px 5px 10px"
      },
      input: {
        //Dont know why but 31px input and 40px textfield is the same height
        height: "31px"
      },
      ...ownSx
    }}/>
  )
}