import { Box, Grid, Typography } from "@mui/material";
import COLORS from "../FortesColors";
import { FortesButtonHighlighted } from "../FortesInputs/FortesButtons";
import CachedIcon from '@mui/icons-material/Cached';

export default function DrawerHeader(props: {
  title: string,
  onClose: () => void,
  onReload: () => void,
}): JSX.Element {
  return (
    <Grid 
      container direction="row" justifyContent="space-between"
      sx={{marginBlock: "5px"}}
    >
      <Box display="flex" alignItems="center" sx={{marginInlineStart:"20px"}}>
        <Typography 
          paddingRight="20px"
          color={COLORS.darkPurple}
          fontSize={"125%"}
          fontWeight={600}
          maxWidth="200px"
          maxHeight="33px"
        >
          {props.title}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-evenly">
        <FortesButtonHighlighted
          highlightColor="white"
          onClick={props.onClose}
        >
          cancel
        </FortesButtonHighlighted>
        <FortesButtonHighlighted
          highlightColor={COLORS.darkPurple}
          contentColor={COLORS.lightPurple}
          icon={<CachedIcon fontSize="medium"/>}
          onClick={props.onReload}
        >
          recalculate
        </FortesButtonHighlighted>
      </Box>
    </Grid>
  )
}